import { useEventFbId } from './../../@libs/client/order/hooks/useLocalCart'
import { genSid } from '@libs/client/helpers'
import { CustomerAddress } from '@libs/client/order/services'
import { useRefDep } from '@libs/client'
import {
  countItems,
  fbpTrack,
  fbpTrackInitAdvanceMatching,
  fbpTrackSingle,
  trunc
} from '@ui/helpers'
import { useAnalytics, useSetting, useSettingDefault } from './useSetting'

export enum PixelEvent {
  ViewContent = 'ViewContent',
  AddToCart = 'AddToCart',
  InitiateCheckout = 'InitiateCheckout',
  AddPaymentInfo = 'AddPaymentInfo',
  Purchase = 'Purchase'
}

const setLoggedEvent = new Set<PixelEvent>()

export const useFbp = () => {
  // const [sellpageSetting] = useSetting('sellpage')
  const analytics = useAnalytics()
  const [eventId, setEventId] = useEventFbId()
  const [storeSetting] = useSetting('store')
  const contentIdRef = useRefDep(analytics?.content_id ?? '')
  const pixelIdsRef = useRefDep(analytics.pixel_ids ?? [])
  const percentPixel =
    useSettingDefault()?.setting.settings?.general?.percent_pixel?.[
      `${storeSetting?.general?.domain}`
    ] || 1

  const fbpTrackViewContent = (price = 0) => {
    if (!setLoggedEvent.has(PixelEvent.ViewContent)) {
      const eventFbId = eventId || genSid()
      fbpTrackSingle(
        PixelEvent.ViewContent,
        {
          content_ids: [contentIdRef.current],
          value: trunc((price || 0) * percentPixel, 2)
        },
        pixelIdsRef.current,
        eventFbId
      )
      if (!eventId) setEventId(eventFbId)
      setLoggedEvent.add(PixelEvent.ViewContent)
    }
  }

  const fbpTrackAddToCart = (price = 0, quantity = 0) => {
    const eventFbId = eventId || genSid()
    fbpTrackSingle(
      'AddToCart',
      {
        content_ids: [contentIdRef.current],
        num_items: quantity,
        value: trunc((quantity * price || 0) * percentPixel, 2)
      },
      pixelIdsRef.current,
      eventFbId
    )
    if (!eventId) setEventId(eventFbId)
  }

  const fbpTrackInitiateCheckout = (items: SfTypes.Item[] = [], items_amount = 0) => {
    const eventFbId = eventId || genSid()
    fbpTrackSingle(
      'InitiateCheckout',
      {
        content_ids: [contentIdRef.current],
        num_items: countItems(items),
        value: trunc((items_amount || 0) * percentPixel, 2)
      },
      pixelIdsRef.current,
      eventFbId
    )
    if (!eventId) setEventId(eventFbId)
  }

  const fbpTrackAddPaymentInfo = (items: SfTypes.Item[] = [], items_amount = 0) => {
    const eventFbId = eventId || genSid()
    fbpTrackSingle(
      'AddPaymentInfo',
      {
        content_ids: [contentIdRef.current],
        num_items: countItems(items),
        value: trunc((items_amount || 0) * percentPixel, 2)
      },
      pixelIdsRef.current,
      eventFbId
    )
    if (!eventId) setEventId(eventFbId)
  }

  const fbpTrackPurchase = (items: SfTypes.Item[] = [], amount = 0) => {
    const eventFbId = eventId || genSid()
    fbpTrackSingle(
      'Purchase',
      {
        content_ids: [contentIdRef.current],
        num_items: countItems(items),
        value: trunc((amount || 0) * percentPixel, 2)
      },
      pixelIdsRef.current,
      eventFbId
    )
    if (!eventId) setEventId(eventFbId)
  }

  const fbpInitAdvanceMatching = (address?: CustomerAddress) => {
    fbpTrackInitAdvanceMatching(
      {
        em: address?.email,
        fn: address?.first_name,
        ln: address?.last_name,
        ph: address?.phone,
        external_id: address?.email,
        ge: '',
        db: '',
        ct: address?.city,
        st: address?.state,
        zp: address?.postal_code,
        country: address?.country
      },
      pixelIdsRef.current
    )
  }

  const fbpTrackViewContentAdsForm = () => {
    fbpTrack('PageView')
    fbpTrack('ViewContent', { content_name: 'register_form' })
  }
  const fbpTrackSubmitApplication = (data?: any) => {
    if (data) {
      fbpTrack('SubmitApplication', data)
    }
  }

  const fbpTrackSubmitSuccess = (data?: any) => {
    if (data) {
      fbpTrack('CompleteRegistration', data)
    }
  }

  return {
    fbpTrackViewContent,
    fbpTrackAddToCart,
    fbpTrackInitiateCheckout,
    fbpTrackAddPaymentInfo,
    fbpTrackPurchase,
    fbpInitAdvanceMatching,
    fbpTrackViewContentAdsForm,
    fbpTrackSubmitApplication,
    fbpTrackSubmitSuccess
  }
}
