import 'assets/scss/index.scss'
import 'regenerator-runtime/runtime'
import 'core-js/stable'
import 'intersection-observer'
import 'swiper/swiper.scss'
import 'swiper/components/lazy/lazy.scss'

import App, { AppContext, AppProps } from 'next/app'
import { IS_SERVER_MOUNTED, useGa, usePromotions, useRedirect, useSetting } from '@ui/hooks'
import {
  getQueryString,
  initializeClientState,
  initializeServerState,
  resetCartBySlug
} from '@ui/helpers'
import {
  expiredDiscountKey,
  genSid,
  preCalcOrder,
  setGlobalState,
  useGlobalState,
  useLocalDiscountCode,
  useLocalOrder,
  useLocalSessionId,
  useLocalShippingAddress,
  useLocalSourceEvent
} from '@libs/client'
import dayjs from 'dayjs'
import { getAddress } from '@ui/services/sync-address'

import DefaultLayout from '@ui/layouts/default'
import Error from './_error'
import MyHead from '@ui/components/next/MyHead'
import { OrderProvider } from '@ui/contexts'
import { initSentry } from '@ui/analytics'
import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { useGtag } from '@ui/hooks/useGtag'
import { useState } from 'react'
import { NextPageContext, NEXT_DATA } from 'next/dist/shared/lib/utils'
import { getLatestUpsell, getLatestVariant } from '@ui/services'
import getConfig from 'next/config'
import { useOrderService } from '@ui/services/shophelp'
import getLogRocket from '@ui/analytics/logRocket'
import { parseCookie } from '@libs/server'
import { useOrderService as useOrderServiceClient } from '@libs/client'
import { UpsellInfoPageId, page_variants_items } from '@libs/common'

declare global {
  interface Window {
    __NEXT_DATA__: NEXT_DATA
    initMap: () => void
  }
}

interface UpsellData {
  upsellInfo: UpsellInfoPageId
  promotionsSellpage?: any
}

// initializeGlobalState()
if (process.browser) {
  initializeClientState()
  window.initMap = () => {
    console.info('')
  }
}
initSentry()
function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const [localSourceEvent, setLocalSourceEvent] = useLocalSourceEvent()
  const [localSessionId, setLocalSissionId] = useLocalSessionId()
  const [sessionInit, setSissionInit] = useState(false)
  const [localOrder, setLocalOrder] = useLocalOrder()
  const [storeSetting] = useSetting('store')
  const isCheckout = router.pathname?.startsWith('/checkout')
  const { calcOrder } = useOrderServiceClient()
  const [, setIsExpiredDiscount] = useGlobalState<boolean>(expiredDiscountKey)
  const promotions = usePromotions()
  const [discountCode, setLocalDiscountCode] = useLocalDiscountCode()
  const [localShipping, setLocalShipping] = useLocalShippingAddress()
  const { getShipping } = useOrderService(getConfig().publicRuntimeConfig.shophelpBasePathApi)
  const [sellpageSetting] = useSetting('sellpage')
  const [store] = useGlobalState<S3Types.store>('store')

  const redirect = useRedirect()

  if (!localSessionId && !sessionInit) {
    setSissionInit(true)
    const initSession =
      router.query?.fbclid ||
      router.query?.gclid ||
      router.query?.sclid ||
      router.query?.ttclid ||
      router.query?.i ||
      genSid()
    setLocalSissionId(initSession as string)
  }
  const prevPath = useRef('')
  const { gtagTrackPageView } = useGtag()
  useGa()

  useEffect(() => {
    const ref = router.query.ref?.toString()
    setGlobalState({ [IS_SERVER_MOUNTED]: false })
    if (ref || (pageProps?.referer && !localSourceEvent)) {
      setLocalSourceEvent({
        referer: ref || pageProps?.referer,
        referer_data: getQueryString(router.query, ['subpath'])
      })
    }
    /**
     * specific listener to update local order items price when variant items price changed1
     * Tickets: https://crossian.atlassian.net/wiki/spaces/tech/pages/312868959/SF+-+Price+Change
     */
    ;(async function () {
      try {
        const temp = [...(localOrder?.items ?? [])]
        if (temp.length) {
          const ids = temp.map((item) => item.variant_id || '')
          const pageIds = temp.map((item) => item.page_id || '')
          const sellpageIds = Object.keys(localOrder?.upsellInfo || {})
          const [variantItems, upsellInfoList] = await Promise.all([
            getLatestVariant(storeSetting?.general.id ?? '', ids, pageIds),
            getLatestUpsell(storeSetting?.general.id ?? '', sellpageIds)
          ])
          if (variantItems) {
            if (isCheckout) {
              const soldOut = Object.values(variantItems).some((e) => !e || e?.sellable === false)
              if (soldOut) {
                // maybe change logic for reset cart in case soldout variant
                resetCartBySlug()
                redirect('/', { query: { error: 'offer-expired' } })
              }
            }

            //set newest data localstorage
            onLoadLatestUpsell(variantItems, upsellInfoList)

            const itemsTmp = temp
              .filter(
                (item) =>
                  item.variant_id &&
                  ((!!variantItems[item.variant_id] && variantItems[item.variant_id].sellable) ||
                    !variantItems[item.variant_id])
              )
              .map((item) => ({
                ...item,
                price:
                  item?.page_id == sellpageSetting?.settings?.general?.id
                    ? variantItems?.[item.variant_id ?? '']?.default_price
                    : localOrder?.variantInCart?.[item?.page_id || '']?.[item.variant_id ?? '']
                        ?.default_price
              }))

            //Filter out cart item that has slug belongs to store
            const filterItemBySlug = itemsTmp?.filter((item) =>
              Object.keys(store?.pages || {})?.includes(item?.slug || '')
            )
            const orderItems: SfTypes.Item[] = calcOrder(filterItemBySlug, false)
            const newOrder = preCalcOrder(orderItems)
            setLocalOrder((prev) => ({ ...prev, ...newOrder, items: orderItems }))
          }
        }
      } catch (error) {
        console.error(`Failed to fetch latest data`, error)
      }
    })()
    getLogRocket().logRocket?.init()
  }, [])

  useEffect(() => {
    const pagePath = router.pathname?.toString()
    if (pagePath !== prevPath.current) {
      const query = window?.location.search
      gtagTrackPageView(`${pagePath}${query}`)
      prevPath.current = pagePath
    }
  }, [router])

  useEffect(() => {
    const promo = router.query?.p?.toString()
    const sclid = router.query?.sclid?.toString()
    const utm_source_email = router.query?.utm_source?.toString()
    const utm_source_sms = router.query?.s?.toString()
    const poId = router.query?.po_id?.toString() || router.query?.d?.toString()
    if (sclid && !localShipping && !poId) {
      getShippingAddress(sclid)
    }
    if (utm_source_email == 'selless' || utm_source_sms == 's') {
      getShippingAddressInPo(poId || '')
    }
    if (
      router.query.utm_source === 'selless' &&
      promo &&
      !!router.query.utm_content?.length &&
      router.query.utm_content?.toString().startsWith('e_')
    ) {
      const expiry = router.query?.expiry?.toString()
      if (expiry) {
        const nowTime = dayjs(new Date())
        const expiryTime = dayjs(new Date(+expiry))
        const offsetInDay = expiryTime.diff(nowTime, 'millisecond')
        if (offsetInDay >= 0) {
          setLocalDiscountCode(promo)
        } else {
          setIsExpiredDiscount(true)
          setLocalDiscountCode('')
        }
      } else {
        setIsExpiredDiscount(true)
        setLocalDiscountCode('')
      }
    } else {
      if (promo) {
        setLocalDiscountCode(promo)
      }
    }
  }, [])

  const onLoadLatestUpsell = (
    variantItems: page_variants_items,
    upsellInfoList: { [slug: string]: UpsellData }
  ) => {
    if (localOrder && Object.keys(localOrder?.variantInCart || {})?.length) {
      Object.keys(localOrder?.variantInCart || {})?.forEach((pageId) => {
        Object.keys(variantItems || {})?.forEach((variantId) => {
          if (pageId && variantId)
            (localOrder.variantInCart || {})[pageId][variantId] = variantItems?.[variantId]
        })
      })
    }
    if (localOrder && Object.keys(localOrder?.upsellInfo || {})?.length) {
      Object.keys(localOrder?.upsellInfo || {})?.forEach((pageId) => {
        ;(localOrder.upsellInfo || {})[pageId] = upsellInfoList?.[pageId]?.upsellInfo
      })
    }
    if (localOrder && Object.keys(localOrder?.promotePercent || {})?.length) {
      Object.keys(localOrder?.promotePercent || {})?.forEach((pageId) => {
        const discountPercentCode =
          discountCode || Object?.keys(upsellInfoList?.[pageId]?.promotionsSellpage || {})?.[0]
        const discountValuePercent: number =
          { ...promotions, ...upsellInfoList?.[pageId]?.promotionsSellpage }?.[
            discountPercentCode || ''
          ]?.type?.toLowerCase() === 'percent'
            ? { ...promotions, ...upsellInfoList?.[pageId]?.promotionsSellpage }?.[
                discountPercentCode || ''
              ]?.value || 0
            : 0
        ;(localOrder.promotePercent || {})[pageId] = discountValuePercent
      })
    }
  }

  const getShippingAddress = async (sclid: string) => {
    const address = await getAddress(sclid)
    if (address) {
      setLocalShipping(address)
    }
  }

  const getShippingAddressInPo = async (id: string) => {
    try {
      if (id) {
        const address = await getShipping(id)
        if (address) {
          setLocalShipping(address)
        }
      }
    } catch (error) {
      console.error('Error get shipping address for RMKT', error)
    }
  }

  if (pageProps?.statusCode >= 400)
    return <Error isRedirectHome statusCode={pageProps.statusCode} />

  return (
    <>
      <MyHead />
      <DefaultLayout>
        <OrderProvider>
          <Component
            {...pageProps}
            key={router?.query?.subpath ? router?.query?.subpath : router?.asPath}
          />
        </OrderProvider>
      </DefaultLayout>
    </>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = App.getInitialProps && (await App.getInitialProps(appContext))
  const nextPageContext: NextPageContext = appContext.ctx

  let initState: { [key: string]: any } = {}
  if (!process.browser) {
    let statusCode: number
    const contextHost = appContext?.ctx?.req?.headers.host?.split(':')?.[0] ?? ''
    const host = (process.env.LOCAL_DOMAIN ?? contextHost) || ''
    const userCookie = parseCookie(appContext?.ctx.req?.headers?.cookie || '')?._version_
    const subpathsInCart = parseCookie(appContext?.ctx.req?.headers?.cookie || '')?._subpath_

    const subpath = appContext.router.query.subpath as string
    const pathname = appContext.router.pathname

    if (subpath !== 'sw.js') {
      initState = initializeServerState(
        host,
        subpath,
        pathname,
        appContext.router.query,
        userCookie,
        subpathsInCart
      )
    }
    setGlobalState({ [IS_SERVER_MOUNTED]: true })
    if (!(initState as any)?.store && appContext.ctx.res) {
      statusCode = 404
    } else if (!process.isAllDataLoaded) {
      statusCode = 503
    } else {
      statusCode = nextPageContext?.res?.statusCode || 200
    }
    if (appContext?.ctx?.res?.statusCode) {
      appContext.ctx.res.statusCode = statusCode
    }

    initState.statusCode = statusCode
  }

  const data = process.browser
    ? window?.__NEXT_DATA__?.props?.pageProps
    : { ...initState, [IS_SERVER_MOUNTED]: true }
  const eventID = nextPageContext?.req?.headers?.['x-request-id'] // unique id for deduplicating fbp events (fbp client and conversion fbp)
  const referer = nextPageContext?.req?.headers?.['referer']
  return {
    ...appProps,
    pageProps: {
      ...appProps?.pageProps,
      ...data,
      eventID,
      referer
    }
  }
}

export default MyApp
