/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
// @ts-ignore
import axiosStatic, { AxiosInstance, AxiosResponse } from 'axios';
export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}
export function axiosAbd(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class CartBvfService {
  /**
   * Create new Cart, or add 1st item to cart with an optional promotion code
   */
  static bvf(
    params: {
      /**  */
      i?: string;
      /**  */
      l?: string;
      /** cart / checkout */
      f?: string;
      /** stripe / paypalex */
      gw?: string;
      /** requestBody */
      body?: CreatingCartBvf;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreatedCart> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/bvf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { i: params['i'], l: params['l'], f: params['f'], gw: params['gw'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Calculate tax amount
   */
  static bvf1(
    params: {
      /** id */
      id: string;
      /** CART/CHECKOUT */
      f?: string;
      /** requestBody */
      body?: CollectTaxRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UpdatedCart> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}/collect-tax/bvf';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { f: params['f'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bvf2(
    params: {
      /** id */
      id: string;
      /** CART/CHECKOUT */
      f?: string;
      /** stripe / paypalex */
      gw?: string;
      /** requestBody */
      body?: UpdateCartBvfRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UpdatedCart> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}/bvf';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { f: params['f'], gw: params['gw'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Apply the discount code to existing cart
   */
  static bvf3(
    params: {
      /**  */
      id: string;
      /**  */
      code?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UpdatedCart> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}/discount/bvf';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { code: params['code'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create Size Calculate
   */
  static sizeCalculate(
    params: {
      /** requestBody */
      body?: SizeCalculateCreateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/size-calculate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create Review
   */
  static review(
    params: {
      /**  */
      pageId: string;
      /**  */
      star: number;
      /**  */
      name: string;
      /**  */
      title: string;
      /**  */
      review: string;
      /**  */
      attachments: [];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/review';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['pageId']) {
        if (Object.prototype.toString.call(params['pageId']) === '[object Array]') {
          for (const item of params['pageId']) {
            data.append('page_id', item as any);
          }
        } else {
          data.append('page_id', params['pageId'] as any);
        }
      }

      if (params['star']) {
        data.append('star', params['star'] as any);
      }

      if (params['name']) {
        if (Object.prototype.toString.call(params['name']) === '[object Array]') {
          for (const item of params['name']) {
            data.append('name', item as any);
          }
        } else {
          data.append('name', params['name'] as any);
        }
      }

      if (params['title']) {
        if (Object.prototype.toString.call(params['title']) === '[object Array]') {
          for (const item of params['title']) {
            data.append('title', item as any);
          }
        } else {
          data.append('title', params['title'] as any);
        }
      }

      if (params['review']) {
        if (Object.prototype.toString.call(params['review']) === '[object Array]') {
          for (const item of params['review']) {
            data.append('review', item as any);
          }
        } else {
          data.append('review', params['review'] as any);
        }
      }

      if (params['attachments']) {
        if (Object.prototype.toString.call(params['attachments']) === '[object Array]') {
          for (const item of params['attachments']) {
            data.append('attachments', item as any);
          }
        } else {
          data.append('attachments', params['attachments'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CartLegacyService {
  /**
   * Create new Cart, or add 1st item to cart with an optional promotion code
   */
  static cart(
    params: {
      /**  */
      i?: string;
      /**  */
      l?: string;
      /** cart / checkout */
      f?: string;
      /** stripe / paypalex */
      gw?: string;
      /** requestBody */
      body?: CreatingCart;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreatedCart> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { i: params['i'], l: params['l'], f: params['f'], gw: params['gw'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Calculate tax amount
   */
  static collectTax(
    params: {
      /** id */
      id: string;
      /** CART/CHECKOUT */
      f?: string;
      /** requestBody */
      body?: CollectTaxRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UpdatedCart> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}/collect-tax';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { f: params['f'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Estimate tax info
   */
  static estimateTax(options: IRequestOptions = {}): Promise<EstimateTaxResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/estimate-tax';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cart1(
    params: {
      /** id */
      id: string;
      /** CART/CHECKOUT */
      f?: string;
      /** stripe / paypalex */
      gw?: string;
      /** requestBody */
      body?: UpdateCartRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UpdatedCart> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { f: params['f'], gw: params['gw'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update shipping info field
   */
  static shipping(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdatingCartShippingField;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}/shipping';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static optOut(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdatingCartOptOut;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}/opt-out';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update billing info
   */
  static billing(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: BillingAddress;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}/billing';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Apply the discount code to existing cart
   */
  static discount(
    params: {
      /**  */
      id: string;
      /**  */
      code?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UpdatedCart> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}/discount';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { code: params['code'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
 * Apply the abandoned code and get cart detail info (return full cart info)
FLOW code-generate: worker scan cart (NOT COMPLETED) and generate the code at ES (cms-abandon-code: id is code) - 3 steps (in SPECS), send to user with url: https://domain.com/a/[code]
FLOW code-apply:
- Browser call API to cms-order to load the Cart and override to localstorage
- Server log the abandon event to ES (cms-abandon-apply: cart_id, code, domain, ip, ua, rid, source - EMAIL/SMS/APP)
 */
  static abandoned(
    params: {
      /**  */
      code?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/abandoned';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { code: params['code'] };
      let data = null;

      configs.data = data;
      axiosAbd(configs, resolve, reject);
    });
  }
  /**
 * Apply the offer code (return offer info)
link "https://{domain}/o/{code}"
 */
  static applyOffer(
    params: {
      /**  */
      code?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferApplyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/order/offer/apply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { code: params['code'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PayCreditcardJsService {
  /**
   * Create Stripe.js PaymentIntent for the card-id
   */
  static cj(
    params: {
      /** CartID */
      id: string;
      /** Session ID */
      i?: string;
      /** Location URL */
      l?: string;
      /** requestBody */
      body?: PayCardJsCreateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayCardJsCreateResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/cj/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { i: params['i'], l: params['l'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Approve Stripe.js credit card payment for the card-id
   */
  static cj1(
    params: {
      /** CartID */
      id: string;
      /** Session ID */
      i?: string;
      /** Location URL */
      l?: string;
      /** requestBody */
      body?: PayCardJsApproveRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayCreditcardResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/cj/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { i: params['i'], l: params['l'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PayCreditcardLegacyService {
  /**
   * Payment testing (ONLY available in DEV environment)
   */
  static cc(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/cc';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Process credit card payment for the card-id
   */
  static cc1(
    params: {
      /** CartID */
      id: string;
      /** Session ID */
      i?: string;
      /** Location URL */
      l?: string;
      /** requestBody */
      body?: PayCreditcardRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayCreditcardResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/cc/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { i: params['i'], l: params['l'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Confirm credit card payment for the card-id
   */
  static confirm(
    params: {
      /** CartID */
      id: string;
      /** Session ID */
      i?: string;
      /** Location URL */
      l?: string;
      /** requestBody */
      body?: CreditcardConfirmRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayCreditcardResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/cc/{id}/confirm';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { i: params['i'], l: params['l'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Process credit card payment for the card-id (card encryption)
   */
  static ccEncrypt(
    params: {
      /** CartID */
      id: string;
      /** Session ID */
      i?: string;
      /** Location URL */
      l?: string;
      /** requestBody */
      body?: PayCreditcardEncryptRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayCreditcardResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/cc-encrypt/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { i: params['i'], l: params['l'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PayPaypalexLegacyService {
  /**
   * Payment testing (ONLY available in DEV environment)
   */
  static px(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/px';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * User create the payment
   */
  static create(
    params: {
      /** CartID */
      id: string;
      /** sessionId */
      i?: string;
      /** location */
      l?: string;
      /** CART/CHECKOUT */
      f?: string;
      /** requestBody */
      body?: PayPaypalexCreateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/px/{id}/create';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { i: params['i'], l: params['l'], f: params['f'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * User approve / paid payment
   */
  static approve(
    params: {
      /** CartID */
      id: string;
      /**  */
      i?: string;
      /**  */
      l?: string;
      /** CART/CHECKOUT */
      f?: string;
      /** requestBody */
      body?: PayPaypalexApproveRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/px/{id}/approve';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { i: params['i'], l: params['l'], f: params['f'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * User cancel the payment
   */
  static cancel(
    params: {
      /** CartID */
      id: string;
      /** sessionId */
      i?: string;
      /** location */
      l?: string;
      /** CART/CHECKOUT */
      f?: string;
      /** requestBody */
      body?: PayPaypalexCancelRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/px/{id}/cancel';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { i: params['i'], l: params['l'], f: params['f'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface AbandonedCart {
  /**  */
  page_slug?: string;

  /**  */
  items?: CartItemResponseBvf[];

  /**  */
  shipping?: CustomerAddress;

  /**  */
  billing?: BillingAddress;

  /**  */
  id?: string;

  /**  */
  code?: string;

  /**  */
  amount?: number;

  /**  */
  items_amount?: number;

  /**  */
  shipping_code?: string;

  /**  */
  shipping_amount?: number;

  /**  */
  shipping_value?: string;

  /**  */
  discount_code?: string;

  /**  */
  discount_value?: string;

  /**  */
  discount_amount?: number;

  /**  */
  tax_amount?: number;

  /**  */
  tax_engine?: tax_engine;

  /**  */
  items_discount_code?: object;
}

export interface BillingAddress {
  /**  */
  same_as_shipping?: boolean;

  /**  */
  email?: string;

  /**  */
  phone?: string;

  /**  */
  last_name?: string;

  /**  */
  first_name?: string;

  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  postal_code?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  country?: string;

  /**  */
  country_code?: string;
}

export interface CartItem {
  /**  */
  id?: string;

  /**  */
  variant_id?: string;

  /**  */
  variant_data?: string;

  /**  */
  quantity?: number;
}

export interface CartItemBvf {
  /**  */
  id?: string;

  /**  */
  variant_id?: string;

  /**  */
  variant_data?: string;

  /**  */
  quantity?: number;

  /**  */
  page_id?: string;
}

export interface CartItemResponseBvf {
  /**  */
  id?: string;

  /**  */
  variant_id?: string;

  /**  */
  variant_data?: string;

  /**  */
  quantity?: number;

  /**  */
  page_id?: string;

  /**  */
  discount_code?: string;

  /**  */
  image_url?: string;

  /**  */
  compare_price?: number;

  /**  */
  default_price?: number;

  /**  */
  title?: string;

  /**  */
  properties?: property_variant[];
}

export interface CollectTaxRequest {
  /** No. 407 */
  address1?: string;

  /** Lynshire Lane */
  address2?: string;

  /** 45840 */
  postal_code?: string;

  /** Findlay */
  city?: string;

  /** Ohio */
  state?: string;

  /** United States */
  country?: string;

  /** US */
  country_code?: string;

  /** CREDITCARD, PAYPALEX */
  method?: string;
}

export interface CreatedCart {
  /**  */
  id?: string;

  /**  */
  code?: string;

  /**  */
  amount?: number;

  /**  */
  items_amount?: number;

  /**  */
  shipping_code?: string;

  /**  */
  shipping_amount?: number;

  /**  */
  shipping_value?: string;

  /**  */
  discount_code?: string;

  /**  */
  discount_value?: string;

  /**  */
  discount_amount?: number;

  /**  */
  tax_amount?: number;

  /**  */
  tax_engine?: tax_engine;


  /**  */
  items?: {[key: string]: number};

  /**  */
  items_discount_code?: object;
}

export interface CreatingCart {
  /**  */
  slug?: string;

  /**  */
  page_id?: string;

  /**  */
  source_referer?: string;

  /**  */
  source_referer_data?: string;

  /**  */
  discount_code?: string;

  /**  */
  items?: CartItem[];
}

export interface CreatingCartBvf {
  /**  */
  source_referer?: string;

  /**  */
  source_referer_data?: string;

  /**  */
  discount_code?: string;

  /**  */
  items?: CartItemBvf[];
}

export interface CreditcardConfirmRequest {
  /**  */
  state?: string;

  /**  */
  payment_intent_id?: string;
}

export interface CustomerAddress {
  /**  */
  email?: string;

  /**  */
  phone?: string;

  /**  */
  last_name?: string;

  /**  */
  first_name?: string;

  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  postal_code?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  country?: string;

  /**  */
  country_code?: string;
}

export interface EstimateTaxResponse {
  /** 102.232.330.33 */
  client_ip?: string;

  /**  */
  domain?: string;

  /** Arizona */
  state?: string;

  /** AZ */
  state_code?: string;

  /** United States */
  country?: string;

  /** US */
  country_code?: string;

  /** 0.69 */
  tax_estimate_product_rate?: number;

  /** 0.03 */
  tax_estimate_shipping_rate?: number;
}

export interface OfferApplyResponse {
  /**  */
  issuccess?: boolean;

  /**  */
  name?: string;

  /**  */
  message?: string;
}

export interface PayCardJsApproveRequest {
  /**  */
  signature?: string;

  /**  */
  payment_intent_id?: string;

  /**  */
  payment_method_id?: string;
}

export interface PayCardJsCreateRequest {
  /**  */
  shipping?: CustomerAddress;
}

export interface PayCardJsCreateResponse {
  /**  */
  signature?: string;

  /**  */
  payment_intent_id?: string;

  /**  */
  payment_intent_secret?: string;
}

export interface PayCreditcardEncryptRequest {
  /**  */
  card_encrypt?: string;

  /**  */
  shipping?: CustomerAddress;
}

export interface PayCreditcardRequest {
  /**  */
  card_number?: string;

  /**  */
  expiry_date?: string;

  /**  */
  cvc?: string;

  /**  */
  shipping?: CustomerAddress;
}

export interface PayCreditcardResponse {
  /**  */
  code?: string;

  /**  */
  transaction_id?: string;

  /**  */
  codes?: { [page_id: string]: string };

  /**  */
  card_first4?: string;

  /**  */
  card_last4?: string;
}

export interface PayPaypalexApproveRequest {
  /**  */
  order_id?: string;
}

export interface PayPaypalexCancelRequest {
  /**  */
  order_id?: string;
}

export interface PayPaypalexCreateRequest {
  /**  */
  return_url?: string;
}

export interface SizeCalculateCreateRequest {
  /**  */
  underbust?: number;

  /**  */
  cup_size?: string;

  /**  */
  email?: string;

  /**  */
  page_id?: string;
}

export interface UpdateCartBvfRequest {
  /**  */
  discount_code?: string;

  /**  */
  cartItems?: CartItemBvf[];
}

export interface UpdateCartRequest {
  /**  */
  discount_code?: string;

  /**  */
  page_id?: string;

  /**  */
  cartItems?: CartItem[];
}

export interface UpdatedCart {
  /**  */
  amount?: number;

  /**  */
  items_amount?: number;

  /**  */
  shipping_code?: string;

  /**  */
  shipping_amount?: number;

  /**  */
  shipping_value?: string;

  /**  */
  discount_code?: string;

  /**  */
  discount_value?: string;

  /**  */
  discount_amount?: number;

  /**  */
  tax_amount?: number;

  /**  */
  tax_engine?: tax_engine;

  /**  */
  items?: {[key: string]: number};

  /**  */
  items_discount_code?: object;

}

export interface UpdatingCartItem {
  /**  */
  id?: string;

  /**  */
  quantity?: number;
}

export interface UpdatingCartOptOut {
  /**  */
  opt_out?: boolean;
}

export interface UpdatingCartShippingField {
  /**  */
  field?: CustomerAddressField;

  /**  */
  value?: string;
}

export interface property_variant {
  /**  */
  name?: string;

  /**  */
  value?: string;
}

export enum CustomerAddressField {
  'email' = 'email',
  'phone' = 'phone',
  'last_name' = 'last_name',
  'first_name' = 'first_name',
  'address1' = 'address1',
  'address2' = 'address2',
  'postal_code' = 'postal_code',
  'city' = 'city',
  'state' = 'state',
  'country' = 'country',
  'country_code' = 'country_code'
}

export enum tax_engine {
  'DEFAULT' = 'DEFAULT',
  'AVATAX' = 'AVATAX',
  'IPSTACK' = 'IPSTACK'
}
