// import { useSetting } from '@ui/hooks'
import {
  maxItemNegative,
  maxQuantityDiscount,
  maxQuantityDiscountProduct,
  preCalcOrder,
  priceChangedVariantItemsKey
} from '@libs/client/helpers'
import { setGlobalState, useGlobalState, useRefDep } from '@libs/client/hooks'
import { useRouter } from 'next/router'
import {
  CartItemBvf,
  CreatedCart,
  UpdatedCart,
  CartLegacyService,
  UpdatingCartItem,
  CustomerAddress,
  CustomerAddressField,
  BillingAddress,
  CollectTaxRequest,
  CartBvfService
} from '../services'
import {
  useIsCollectTax,
  useLocalBuyNowOrder,
  useLocalDiscountCode,
  useLocalIsBuyNow,
  useLocalOrder,
  useLocalOrderId,
  useLocalSessionId,
  useLocalSourceEvent
} from './useLocalCart'
import { useTrackingService } from './useTrackingService'
import { method_type } from '@libs/common/models/constant'
import { usePromotions, usePromotionsSellpage, useSetting } from '@ui/hooks'
import { convertNumber, trunc } from '@ui/helpers'
import { Item } from '@libs/common'

interface RunningPerPageId {
  quantity: number
  lastItemDiscount: number
  isLastItemSameMax: boolean
  itemIndex: number
}

const getOrderItems = (items: SfTypes.Item[]): CartItemBvf[] =>
  items.map((item) => ({
    id: item.id,
    quantity: item.quantity,
    variant_data: item.variant_data,
    variant_id: item.variant_id,
    page_id: item.page_id
  }))

export const useOrderService = () => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [localOrderId, setLocalOrderId] = useLocalOrderId()
  const [localOrder, setLocalOrder] = useLocalOrder()
  const [localBuyNowOrder, setLocalBuyNowOrder] = useLocalBuyNowOrder()
  const [localIsBuyNow, setLocalIsBuyNow] = useLocalIsBuyNow()
  const localIsBuyNowRef = useRefDep(localIsBuyNow)
  const localBuyNowOrderRef = useRefDep(localBuyNowOrder)
  const [localDiscountCode] = useLocalDiscountCode()
  const [localSourceEvent] = useLocalSourceEvent()
  const slugRef = useRefDep(slug)
  const localOrderIdRef = useRefDep(localOrderId)
  const localDiscountCodeRef = useRefDep(localDiscountCode)
  const localSourceEventRef = useRefDep(localSourceEvent)
  const [localSessionId] = useLocalSessionId()
  const isSelless = router.query?.s || router.query?.selless
  const { trackingInitCheckout } = useTrackingService()
  const [, setIsCollect] = useIsCollectTax()
  const [storeSetting] = useSetting('sellpage')
  const [discountCode] = useLocalDiscountCode()
  const promotions = usePromotions()
  const [promotionsSellpage] = usePromotionsSellpage()
  const discountPercentCode = discountCode || Object?.keys(promotionsSellpage || {})?.[0]
  const thisPageId = storeSetting?.settings?.general?.id || ''
  const variantItems = storeSetting?.variants?.items ?? {}
  const isSellpageUpsell = storeSetting?.settings?.general?.upsell || false
  const discountPercentUpsell = storeSetting?.settings?.general?.upsell_info?.discount_percent || 0
  const initiateDiscountPercentUpsell =
    storeSetting?.settings?.general?.upsell_info?.initiate_discount_percent || 0

  const discountValuePercent: number =
    { ...promotions, ...promotionsSellpage }?.[discountPercentCode || '']?.type?.toLowerCase() ===
    'percent'
      ? { ...promotions, ...promotionsSellpage }?.[discountPercentCode || '']?.value || 0
      : 0

  const [, setMaxQuantityDiscount] = useGlobalState<{ [pageId: string]: number }>(
    maxQuantityDiscount
  )
  const [, setMaxQuantityDiscountProduct] = useGlobalState<{ [pageId: string]: number }>(
    maxQuantityDiscountProduct
  )
  const [, setMaxItemNegative] = useGlobalState<{ [pageId: string]: boolean }>(maxItemNegative)

  const localOrderRef = useRefDep(localOrder)
  const updateLocalData = (
    result: CreatedCart & UpdatedCart,
    isBuyNow: boolean,
    currentItems: SfTypes.Item[]
  ) => {
    setLocalIsBuyNow(isBuyNow ?? false)
    if (result.id) setLocalOrderId(result.id)
    if (result.items) {
      const updatedItems: SfTypes.PriceChangedVariantItems = {}
      currentItems = currentItems.map((item) => {
        const price = result.items?.[item.id]
        if (price !== item.price && item.variant_id)
          updatedItems[item.variant_id] = { default_price: price }
        return {
          ...item,
          price: price ?? item.price
        }
      })
      setGlobalState({ [priceChangedVariantItemsKey(slugRef.current)]: updatedItems })
    }
    setLocalBuyNowOrder((prev: any) => ({
      ...prev,
      ...(result.id ? { id: result.id /*, code: result.code*/ } : {}),
      ...(isBuyNow ? { ...result, items: currentItems } : {})
    }))
    setLocalOrder((prev: any) => ({
      ...prev,
      ...(result.id ? { id: result.id /*, code: result.code*/ } : {}),
      ...(!isBuyNow ? { ...result, items: currentItems } : {})
    }))
  }

  const initItems = async (items: SfTypes.Item[], isBuyNow?: boolean, isStripe = false) => {
    const orderItems = getOrderItems(items)
    try {
      let service: Promise<CreatedCart & UpdatedCart>
      if (localOrderIdRef.current) {
        service = CartBvfService.bvf2({
          id: localOrderIdRef.current,
          f: isBuyNow ? 'checkout' : 'cart',
          gw: isBuyNow ? '' : isStripe ? 'STRIPE' : 'PAYPALEX',
          body: {
            cartItems: orderItems,
            discount_code: localDiscountCodeRef.current || ''
          }
        })
        if (isSelless) {
          trackingInitCheckout()
        }
      } else {
        service = CartBvfService.bvf({
          i: localSessionId || undefined,
          l: encodeURIComponent(window.location.href) || '',
          f: isBuyNow ? 'checkout' : 'cart',
          gw: isBuyNow ? '' : isStripe ? 'STRIPE' : 'PAYPALEX',
          body: {
            items: orderItems,
            ...(localDiscountCodeRef.current
              ? { discount_code: localDiscountCodeRef.current }
              : {}),
            ...(slugRef.current ? { slug: slugRef.current } : {}),
            ...(localSourceEventRef.current
              ? {
                  source_referer: localSourceEventRef.current.referer,
                  source_referer_data: localSourceEventRef.current.referer_data
                }
              : {})
          }
        })
      }
      const result = await service
      updateLocalData(result, isBuyNow ?? false, items)
      return { ...result, items: items }
    } catch (err: any) {
      const errCode = err?.response?.data?.status
      if (errCode === 404) {
        const result = await CartBvfService.bvf({
          i: localSessionId || undefined,
          l: encodeURIComponent(window.location.href) || '',
          f: isBuyNow ? 'checkout' : 'cart',
          gw: isBuyNow ? '' : isStripe ? 'STRIPE' : 'PAYPALEX',
          body: {
            items: orderItems,
            ...(localDiscountCodeRef.current
              ? { discount_code: localDiscountCodeRef.current }
              : {}),
            ...(slugRef.current ? { slug: slugRef.current } : {})
          }
        })
        updateLocalData(result, isBuyNow ?? false, items)
        return { ...result, items: items }
      } else {
        throw err
      }
    }
  }

  const calcOrder = (items: SfTypes.Item[], isGetItemsProductForm = false) => {
    const listUnixSorted = getUnixSortedPageId(items)

    const runningData = listUnixSorted?.reduce((acc, next) => {
      return {
        ...acc,
        [next]: {
          quantity: 0,
          lastItemDiscount: 0,
          isLastItemSameMax: false,
          itemIndex: 0
        }
      }
    }, {}) as { [pageId: string]: RunningPerPageId }

    const maxNegativePageId = listUnixSorted?.reduce((acc, next) => {
      return {
        ...acc,
        [next]: false
      }
    }, {}) as { [pageId: string]: boolean }

    const itemsTemp = [...items].map((x) => {
      const {
        thisVariantInfo,
        thisPromotePercent,
        thisDiscountPercent,
        thisInitiateDiscountPercentUpsell
      } = getDataInMultipleCart(x?.page_id || '')

      let totalAmount = 0
      let itemDefaultPrice = 0
      const quantityTmp = runningData?.[x?.page_id || '']?.quantity + (x?.quantity || 0)
      const variantInfo = thisVariantInfo?.[x?.variant_id || '']
      const defaultPrice = variantInfo?.default_price || 0
      const minPrice =
        trunc(variantInfo?.fulfillment_cost || 0, 2) +
        trunc(variantInfo?.product_cost || 0, 2) +
        2 +
        trunc((thisPromotePercent * defaultPrice) / 100, 2)
      const maxItem = ~~(
        (defaultPrice - minPrice - (thisInitiateDiscountPercentUpsell * defaultPrice) / 100) /
        ((defaultPrice * thisDiscountPercent) / 100)
      )
      setTimeout(() => {
        maxNegativePageId[x?.page_id || ''] = maxItem <= 0
        setMaxItemNegative(maxNegativePageId)
      }, 1)
      if (maxItem < 0) {
        return {
          ...x
        }
      }
      if (runningData?.[x?.page_id || '']?.lastItemDiscount > maxItem) {
        runningData[x?.page_id || ''].lastItemDiscount = maxItem
      }
      let itemDefaultPriceTmp = trunc(
        (defaultPrice *
          (100 -
            (thisInitiateDiscountPercentUpsell +
              runningData?.[x?.page_id || '']?.lastItemDiscount * thisDiscountPercent))) /
          100,
        2
      )
      for (let i = 0; i < (x?.quantity || 0); i++) {
        runningData[x?.page_id || ''].itemIndex++
        // next item
        if (runningData?.[x?.page_id || '']?.itemIndex > 1) {
          // compare last_item_discount vs max_item_discount
          if (runningData?.[x?.page_id || '']?.lastItemDiscount < maxItem) {
            runningData[x?.page_id || ''].lastItemDiscount++
            itemDefaultPriceTmp =
              defaultPrice *
              ((100 -
                (thisInitiateDiscountPercentUpsell +
                  runningData?.[x?.page_id || '']?.lastItemDiscount * thisDiscountPercent)) /
                100)
          }
          itemDefaultPrice = trunc(itemDefaultPriceTmp, 2)
        } // first item
        else itemDefaultPrice = defaultPrice
        totalAmount = (totalAmount * 100 + itemDefaultPrice * 100) / 100
      }
      runningData[x?.page_id || ''].isLastItemSameMax =
        runningData?.[x?.page_id || '']?.lastItemDiscount == maxItem
      const price = trunc(convertNumber(totalAmount, x?.quantity || 1), 2)
      runningData[x?.page_id || ''].quantity = quantityTmp
      return {
        ...x,
        price
      }
    })
    if (isGetItemsProductForm) {
      const maxQuantityProductData = listUnixSorted?.reduce((acc, next) => {
        return { ...acc, [next]: runningData?.[next]?.lastItemDiscount }
      }, {})
      setMaxQuantityDiscountProduct(maxQuantityProductData)
    } else {
      const maxQuantityData = listUnixSorted?.reduce((acc, next) => {
        return {
          ...acc,
          [next]: runningData?.[next]?.isLastItemSameMax
            ? runningData?.[next]?.lastItemDiscount
            : runningData?.[next]?.lastItemDiscount + 1
        }
      }, {})
      setMaxQuantityDiscount(maxQuantityData)
    }
    return itemsTemp
  }

  /**
   * Add new items to cart
   *
   * @param items items to add more to normal cart
   */
  const addItems = (items: SfTypes.Item[]) => {
    let currentItems = []

    if (isSellpageUpsell) {
      currentItems = calcOrder([...(localOrder?.items ?? []), ...items])
    } else {
      currentItems = [...(localOrder?.items ?? []), ...items]
    }
    const result = preCalcOrder(currentItems)

    const {
      buildUpsellInfo,
      buildVariantInCart,
      buildDiscountValuePercent
    } = AddInfoVariantWhenAddItems()

    setLocalIsBuyNow(false)
    setLocalOrder({
      ...localOrder,
      ...result,
      items: currentItems,
      upsellInfo: buildUpsellInfo,
      variantInCart: buildVariantInCart,
      promotePercent: buildDiscountValuePercent
    })
  }

  /**
   * Remove items in cart by item ids
   * @param itemIds
   */
  const removeItems = (itemIds: string[]) => {
    const temp = [...(localOrder?.items ?? [])]
    let items = []

    if (isSellpageUpsell) {
      items = calcOrder(temp.filter((item) => !itemIds.includes(item.id)))
    } else {
      items = temp.filter((item) => !itemIds.includes(item.id))
    }

    const result = preCalcOrder(items)
    setLocalOrder({
      ...localOrder,
      ...result,
      items
    })
  }

  /**
   * Update existed items with new quantity
   * @param items
   */
  const updateItems = (items: UpdatingCartItem[], isAddtoCart = false) => {
    if (items.length > 0) {
      const temp = [...(localOrder?.items ?? [])]
      const tempItems = temp.map((item) => {
        const match = items.find((_item) => _item.id === item.id)
        return match ? { ...item, quantity: match.quantity } : item
      })
      let preTempItems: Item[] = []

      if (!isAddtoCart) {
        preTempItems = tempItems
      } else {
        const removeTemp = tempItems?.filter((i) => i?.id != items[0]?.id)
        const matchItem = tempItems.find((_item) => _item.id === items[0]?.id) as Item
        preTempItems = [...removeTemp, matchItem]
      }
      let dataItems = []

      const isThisItemUpsell = checkIsUpsellVariant(items?.[0]?.id || '')
      if (isThisItemUpsell) {
        dataItems = calcOrder(preTempItems)
      } else {
        dataItems = preTempItems
      }

      const result = preCalcOrder(dataItems)

      setLocalIsBuyNow(false)
      setLocalOrder({
        ...localOrder,
        ...result,
        items: dataItems
      })
    }
  }

  const checkIsUpsellVariant = (id: string) => {
    const thisPageId = localOrder?.items?.find((x) => x?.id == id)?.page_id || ''
    return localOrder?.upsellInfo?.[thisPageId]?.isUpsell || false
  }

  const getDataInMultipleCart = (pageId: string) => {
    const thisVariantInfo = localOrder?.variantInCart?.[pageId] ?? variantItems ?? {}
    const thisPromotePercent = localOrder?.promotePercent?.[pageId] ?? discountValuePercent ?? 0
    const thisDiscountPercent =
      localOrder?.upsellInfo?.[pageId]?.discountPercent ?? discountPercentUpsell ?? 0
    const thisInitiateDiscountPercentUpsell =
      localOrder?.upsellInfo?.[pageId]?.initiateDiscountPercentUpsell ??
      initiateDiscountPercentUpsell ??
      0

    return {
      thisVariantInfo,
      thisPromotePercent,
      thisDiscountPercent,
      thisInitiateDiscountPercentUpsell
    }
  }

  const AddInfoVariantWhenAddItems = () => {
    const buildUpsellInfo = {
      ...localOrder?.upsellInfo,
      [thisPageId]: {
        isUpsell: isSellpageUpsell,
        discountPercent: discountPercentUpsell,
        initiateDiscountPercentUpsell
      }
    }

    const buildVariantInCart = {
      ...(localOrder?.variantInCart || {}),
      [thisPageId]: variantItems
    }

    const buildDiscountValuePercent = {
      ...(localOrder?.promotePercent || {}),
      [thisPageId]: discountValuePercent
    }
    return { buildUpsellInfo, buildVariantInCart, buildDiscountValuePercent }
  }

  const updateShipping = <K extends keyof CustomerAddress>(
    field: CustomerAddressField,
    value: CustomerAddress[K]
  ) => {
    if (localOrderIdRef.current) {
      try {
        CartLegacyService.shipping({
          id: localOrderIdRef.current,
          body: {
            field,
            value
          }
        })
      } catch (err: any) {
        console.error(err)
      }
    }
  }
  const updateTaxAmount = async (
    address: CustomerAddress,
    method: method_type,
    isCancelPaypal = false
  ) => {
    const body: CollectTaxRequest = {
      address1: address?.address1 || '',
      address2: address?.address2 || '',
      city: address?.city || '',
      country: address?.country || '',
      country_code: address?.country_code || '',
      postal_code: address?.postal_code || '',
      state: address?.state || '',
      method
    }
    if (localOrderIdRef.current) {
      try {
        if (method === method_type.CREDITCARD && !isCancelPaypal) {
          setIsCollect(true)
        }
        const res = await CartBvfService.bvf1({
          f: localIsBuyNowRef.current ? 'checkout' : 'cart',
          id: localOrderIdRef.current,
          body
        })
        updateLocalData(
          res,
          localIsBuyNowRef.current ?? false,
          (localIsBuyNowRef.current
            ? localBuyNowOrderRef?.current?.items
            : localOrderRef?.current?.items) as SfTypes.Item[]
        )
        return res
      } catch (err: any) {
        console.error('Error with update Tax', err)
        throw err
      }
    }
  }
  const updateOtpOut = (opt_out: boolean) => {
    if (localOrderIdRef.current) {
      try {
        CartLegacyService.optOut({
          id: localOrderIdRef.current,
          body: {
            opt_out
          }
        })
      } catch (err: any) {
        console.error(err)
      }
    }
  }

  const updateShippingAddress = async (address: CustomerAddress) => {
    if (localOrderIdRef.current) {
      try {
        const id = localOrderIdRef.current
        await Promise.all(
          Object.entries(address).map(async (field) => {
            const [key, value] = field as [CustomerAddressField, string]
            await CartLegacyService.shipping({
              id,
              body: {
                field: key,
                value
              }
            })
          })
        )
      } catch (err: any) {
        console.error(err)
        throw err
      }
    }
  }

  const updateBilling = async (orderId?: string, billing?: BillingAddress) => {
    if (orderId) {
      try {
        await CartLegacyService.billing({
          id: orderId,
          body: billing
        })
      } catch (err: any) {
        console.error(err)
        throw err
      }
    }
  }

  const getUnixSortedPageId = (items: Item[] = []) => {
    if (items?.length) return Array.from(new Set(items?.map((x) => x?.page_id || '')))
    else
      return (
        Array.from(
          new Set(localOrder?.items?.map((x) => x?.page_id || '')?.reverse())
        )?.reverse() || []
      )
  }

  return {
    initItems,
    addItems,
    removeItems,
    updateItems,
    updateShipping,
    updateShippingAddress,
    updateBilling,
    updateOtpOut,
    updateTaxAmount,
    calcOrder
  }
}
