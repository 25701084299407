import axios from 'axios'

export const getPageBySlug = async (storeId: string, slug: string) => {
  const _sellpages: S3Types.pages = (
    await axios.get(`/api/stores/${storeId}/pages?subpaths=${JSON.stringify([slug])}`)
  )?.data as S3Types.pages

  return _sellpages?.[slug]
}

export const getLatestVariant = async (
  storeId: string,
  variantIds: string[],
  pageIds: string[]
) => {
  const variants: S3Types.page_variants_items = (
    await axios.get(
      `/api/variants/${storeId}?variant_ids=${variantIds.join(',')}&page_ids=${pageIds.join(',')}`
    )
  )?.data as S3Types.page_variants_items

  return variants
}

export const getLatestUpsell = async (storeId: string, sellpageIds: string[]) => {
  const dataUpsell = (
    await axios.get(`/api/sellpages/${storeId}?sellpages_ids=${sellpageIds.join(',')}`)
  )?.data

  return dataUpsell
}
