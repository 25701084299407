import { NextPage } from 'next'
import { useEffect } from 'react'
import css from 'styled-jsx/css'

interface ErrorProps {
  statusCode?: number
  isRedirectHome?: boolean
}

const Error: NextPage<ErrorProps> = (props) => {
  const statusCode = props?.statusCode || 404
  useEffect(() => {
    if (props?.isRedirectHome) {
      window.location.href = `/${window.location.search}`
    }
  }, [])
  return (
    <>
      <style jsx global>
        {globalStyle}
      </style>
      {!props?.isRedirectHome && (
        <div className="not-found-page page">
          <div className="page__content-wrapper container">
            <div className="page__content">
              <div className="has-text-centered">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="90"
                  height="90"
                  fill="#DBE1EC"
                  viewBox="0 0 48 48"
                >
                  <path d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z"></path>
                </svg>
                <div className="title">
                  {statusCode === 404
                    ? 'Page not found!'
                    : status[statusCode] || 'An error occurred'}
                </div>
                <a
                  className="is-primary is-large"
                  onClick={() => (window.location.href = `/${window.location.search}`)}
                >
                  Back to the Home page
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const globalStyle = css.global`
  .not-found-page {
    .page__content {
      display: flex;
      align-items: center;
    }
  }
`

const status: { [key: number]: string } = {
  100: 'Continue',
  101: 'Switching Protocols',
  102: 'Processing',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi-Status',
  208: 'Already Reported',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  418: 'I am a teapot',
  421: 'Misdirected Request',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  444: 'Connection Closed Without Response',
  451: 'Unavailable For Legal Reasons',
  499: 'Client Closed Request',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required',
  599: 'Network Connect Timeout Error'
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode }
}

export default Error
