import getConfig from 'next/config'

const cdnBasePath = getConfig().publicRuntimeConfig.cdnBasePath
const cdnBasePathBvf = getConfig().publicRuntimeConfig.cdnBasePathBvf
const cdnImages = getConfig().publicRuntimeConfig.cdnImages
const cdnImagesBvf = getConfig().publicRuntimeConfig.cdnImagesBvf
const previewImagePath = getConfig().publicRuntimeConfig.previewImagePath

export const iconPath = (url: string) => `${cdnBasePath}/${cdnImages}/${url}`
export const iconPathBvf = (url: string) => `${cdnBasePathBvf}/${cdnImagesBvf}/${url}`
export const imagePath = (url: string) => `${cdnBasePath}/${url}`

export const previewImage = (path = '') => `${previewImagePath}${path}`
