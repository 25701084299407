import getConfig from 'next/config'
import NodeRSA from 'node-rsa'

const rsaPublicKey = getConfig().publicRuntimeConfig.rsaPublicKey

export const toDictionary = <T, K>(
  arr: T[],
  keyFn: (item: T) => string,
  itemFn: (item: T) => K
) => {
  return arr.reduce((total, item) => {
    total[keyFn(item)] = itemFn(item)
    return total
  }, {} as { [key: string]: K })
}

export const rsaEncryptData = (data: any) => {
  if (process.browser) {
    try {
      const publicKey = new NodeRSA(rsaPublicKey, 'pkcs8-public')
      return publicKey.encrypt(data, 'base64')
    } catch (err: any) {
      console.error(err)
      return ''
    }
  }
  return ''
}
