import { useRouter } from 'next/router'
import * as immutable from 'object-path-immutable'
import { useEffect, useState } from 'react'
import { store_type } from '@libs/common/models/constant'
import { useNotiContext } from '@ui/components/shared/Notification'
import { getPageBySlug } from '@ui/services'

export interface IFetchSellPage {
  storeSetting: S3Types.store_settings | null
  sellpageSetting: S3Types.page_setting | null
  setStore: (
    nextState: S3Types.store | ((currentState: S3Types.store) => void),
    event?: string | undefined
  ) => void
}

export function useFetchSellPage({ storeSetting, sellpageSetting, setStore }: IFetchSellPage) {
  const router = useRouter()
  const subpath = router.query.subpath?.toString()
  const isMultiStore = storeSetting?.general?.type === store_type.MINISTORE
  const { notiDispatch } = useNotiContext()

  const [loading, setLoading] = useState(isMultiStore && subpath && !sellpageSetting?.settings)

  useEffect(() => {
    if (isMultiStore && subpath && !sellpageSetting?.settings) {
      ;(async function () {
        try {
          setLoading(true)
          const _page = await getPageBySlug(storeSetting?.general.id ?? '', subpath)
          if (_page?.setting) {
            setStore((store: S3Types.store) => {
              const _store = immutable.set(store, `pages.${subpath}.setting`, _page.setting)
              if (_store?.pages?.[subpath]) {
                _store.pages[subpath].reviews = _page.reviews
                _store.pages[subpath].ratings = _page.ratings
                _store.pages[subpath].mapping_size = _page.mapping_size
                _store.pages[subpath].mapping_properties = _page.mapping_properties
              }
              return _store
            })
          }
        } catch (error) {
          notiDispatch({
            payload: { content: 'Can not get sell page', type: 'is-danger' },
            type: 'REMOVE_ALL_AND_ADD'
          })
        } finally {
          setLoading(false)
        }
      })()
    } else {
      setLoading(false)
    }
  }, [router])

  return [loading]
}
