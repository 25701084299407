import getConfig from 'next/config'

const config = getConfig().publicRuntimeConfig

export const originalImage = (path = '') => `${config.cdnBasePath}/${path}`

export const thumbImage = (settings: string) => (path = '') =>
  `${config.cdnBasePath}/${config.cdnThumbs}/${settings}/${path}`

export const originalImageBvf = (path = '') =>
  `${config.cdnBasePathBvf}/${config.cdnImagesReal}/${path}`

export const thumbImageBvf = (path = '') =>
  `${config.cdnBasePathBvf}/${config.cdnThumbsBvf}/${path}`
