import { getCookie } from '@ui/helpers/getCookie'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useSetting } from './useSetting'

export function useVersionCookie() {
  const [version, setVersion] = useState<string>('')
  const [storeSetting] = useSetting('store')
  const router = useRouter()
  useEffect(() => {
    setVersion(
      (router.query.subpath == 'cupipads' || router.query.subpath == 'cupi') &&
        storeSetting?.general?.domain === 'vabeli.com'
        ? getCookie('_version_')
        : ''
    )
  }, [])
  return version
}
