export const localOrderIdKey = '_local_order_id'
export const localOrderKey = '_local_order'
export const localBuyNowOrderKey = '_local_buynow_order'
export const localPurchasedOrderKey = '_local_purchased_order'
export const localIsBuyNowKey = '_local_isbuynow'
export const localIsPayingKey = '_local_ispaying'
export const localDiscountCodeKey = (slug?: string) =>
  slug ? `_local_${slug}_discount_code` : '_local_discount_code'
export const localSourceEventKey = (slug?: string) =>
  slug ? `_local_${slug}_source_event` : '_local_source_event'
export const localShippingAddressKey = '_local_shipping'
export const isPayingWithPaypalKey = 'is_paying_paypal'
export const priceChangedVariantItemsKey = (slug?: string) =>
  slug ? `items_${slug}_changed_price` : 'items_changed_price'
export const sessionIdKey = '_local_session_id'
export const isPuchaseKey = (slug?: string) =>
  slug ? `_local_${slug}_is_puchase` : '_local_is_puchase'

export const stripePaymentConfirm = '_local_stripe_confirm'
export const localStripePaymentConfirmKey = (slug?: string) =>
  slug ? `_local_${slug}_stripe_confirm` : '_local_stripe_confirm'
export const localOtpOutKey = (slug?: string) => (slug ? `_local_${slug}_otpout` : '_local_otpout')
export const localpaymentTypeOrderKey = '_local_payment_type_order'

export const localIsCollectTax = '_local_is_collect_tax'

export const localIsPaypalThankYouId = (slug?: string) =>
  slug ? `_local_is_paypal_${slug}_thank_you` : 'is_paypal_thank_you'

export const localOrderPaypalId = (slug?: string) =>
  slug ? `_local_${slug}_paypal_order_id` : '_local_paypal_order_id'
export const maxQuantityDiscount = 'max_quantity_discount'
export const maxQuantityDiscountProduct = 'max_quantity_discount_product'
export const maxItemNegative = 'max_item_negative'
export const expiredDiscountKey = 'is_expired_discount'
export const isShowCartModalKey = 'cart_modal'
export const isSizeCalculatorKey = 'size_calculator_key'
export const eventIdFbKey = 'event_id_fb'
export const versionCookie = ['bv10', 'bv11', 'bv12', 'bv13', 'bv14']
