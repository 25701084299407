import { getCookie } from 'src/helpers/getCookie'
import { OrderItem } from './../../../../src/types/interfaces/order.d'
import useAxios from '@ui/services/useAxios'
import getConfig from 'next/config'
const propertiesText = (properties?: S3Types.item_properties) =>
  Object.values(properties ?? {}).join(' / ')

import { useLocalSessionId } from './useLocalCart'
import { versionCookie } from '@libs/client/helpers'
const kongBasePath = getConfig().publicRuntimeConfig.kongBasePath

export const useTrackingService = () => {
  const [localSessionId] = useLocalSessionId()
  const userCookie = getCookie('_version_') || ''
  const { instance } = useAxios(kongBasePath, {
    version: `${versionCookie.includes(userCookie) ? userCookie : ''}`
  })
  const location = (process.browser && encodeURIComponent(window.location.href)) || ''
  const trackingRemoveToCart = (selectedVariant?: OrderItem | null, quantity = 0) => {
    try {
      instance.post(
        `/c/r?i=${localSessionId}&v=${propertiesText(
          selectedVariant?.properties
        )}&n=${quantity}&l=${location}`
      )

      // CartLegacyService.addToCart({
      //   i: localSessionId || undefined,
      //   l: location
      // })
    } catch (error) {
      console.error(`Add remove add to cart tracking`, error)
    }
  }
  const trackingAddToCart = (selectedVariant?: OrderItem | null, quantity = 0) => {
    try {
      instance.post(
        `/c/a?i=${localSessionId}&v=${propertiesText(
          selectedVariant?.properties
        )}&n=${quantity}&l=${location}`
      )
      // instance.post(`/c?i=${localSessionId}&l=${location}`)
    } catch (error) {
      console.error(`Add to card tracking`, error)
    }
  }
  const trackingViewItem = () => {
    try {
      instance.post(`/v?i=${localSessionId}&l=${location}`)
    } catch (error) {
      console.error(`Add to view item tracking`, error)
    }
  }
  const trackingInitCheckout = () => {
    try {
      instance.post(`/ico?i=${localSessionId}&l=${location}`)
    } catch (error) {
      console.error(`Add to init checkout tracking`, error)
    }
  }

  const trackingViewSurvey = () => {
    try {
      instance.post(`/s/v?l=${location}`)
    } catch (error) {
      console.error(`Add to view item tracking`, error)
    }
  }
  const trackingSubmitSurvey = () => {
    try {
      instance.post(`/s/s?l=${location}`)
    } catch (error) {
      console.error(`Add to view item tracking`, error)
    }
  }
  const trackingExpiredSurvey = () => {
    try {
      instance.post(`/s/e?l=${location}`)
    } catch (error) {
      console.error(`Add to view item tracking`, error)
    }
  }

  return {
    trackingAddToCart,
    trackingRemoveToCart,
    trackingViewItem,
    trackingInitCheckout,
    trackingViewSurvey,
    trackingSubmitSurvey,
    trackingExpiredSurvey
  }
}
