import * as immutable from 'object-path-immutable'

import { buildCollections, deepCloneObject, removeUnavailableProperties } from '@libs/server'
import {
  getLocalState,
  setPrefix,
  syncLocalTab
} from '@libs/client/hooks/useGlobalState/StateLocal'
import { ParsedUrlQuery } from 'querystring'
import { hardcodePaygateContact } from './hardcode'
import { initRDT } from '@libs/client/hooks/useGlobalState/ReduxDevTool'
import { setGlobalState } from '@libs/client'
export interface ServerState {
  domains?: S3Types.domains
  publisher?: S3Types.publisher
  store?: S3Types.store
  promotions?: {
    default: S3Types.promotions
    [slug: string]: S3Types.promotions
  }
  paygates?: { creditcard?: S3Types.paygate; paypalex?: S3Types.paygate }
  static?: {
    [static_slug: string]: S3Types.default_store_static_page
  }
  isPreview?: boolean
  analytics?: {
    content_id?: string
    pixel_ids: string[]
    tiktok_ids: string[]
    snapchat_ids: string[]
    pinterest_ids: string[]
    google_conversion_id: string
    google_conversion_label: string
  }
  settingDefault?: S3Types.default_store
  settingBvf?: S3Types.bvf_default
  bvf_domain_settings?: S3Types.bvf_domain_settings
  surveys?: S3Types.surveys
}

export function initializeClientState() {
  const INIT_STATE = {
    ...setPrefix(window?.__NEXT_DATA__?.props?.pageProps, ''),
    ...getLocalState()
  }
  setGlobalState(INIT_STATE, true)
  initRDT(INIT_STATE)
  // Set to false to avoid clearing state, this should be changed once we apply sync between tabs of browser.
  syncLocalTab((data: any) => setGlobalState(data))
}

const imageMapping: { [key: string]: string } = {
  'p/2d13drdcbzdv4yond9oja1pua/i/19dlwnbxmznnpymjrwue0otpk.jpg':
    'p/2d13drdcbzdv4yond9oja1pua/i/304tkzhmpwl2kujjbtxs2jmnm_1.jpg',
  'p/2d13drdcbzdv4yond9oja1pua/i/4v1qsbfwl3zz4tlpnt7d0cflm.jpg':
    'p/2d13drdcbzdv4yond9oja1pua/i/7996lyuvg6ifcsh4su7q0dt7f_2.jpg',
  'p/2d13drdcbzdv4yond9oja1pua/i/70pkxky25tvtbxt66dv6blcp6.jpg':
    'p/2d13drdcbzdv4yond9oja1pua/i/5oqh3vquy51dzg41xo1zxqxmg_3.jpg',
  'p/2d13drdcbzdv4yond9oja1pua/i/colusan59o7wmavoq8rf7otg.jpg':
    'p/2d13drdcbzdv4yond9oja1pua/i/6la77jq76av20s84djsvtnje0_4.jpg',
  'p/2d13drdcbzdv4yond9oja1pua/i/5my0on9dyze4yr2egs9flr348.jpg':
    'p/2d13drdcbzdv4yond9oja1pua/i/1ln6uxosp3hlywonyl6gd06r7_5.jpg',
  'p/2d13drdcbzdv4yond9oja1pua/i/5y7v6kzyxle5o3bn5bfu0c1ny.jpg':
    'p/2d13drdcbzdv4yond9oja1pua/i/1u0cbi378q1lmfkmknbvffu04_6.jpg',
  'p/2d13drdcbzdv4yond9oja1pua/i/5e04gm2qgrc1tpfou2oiyfn5u.jpg':
    'p/2d13drdcbzdv4yond9oja1pua/i/h8b7ctv3gdpdmknx03gqxa8q_7.jpg'
}

export function initializeServerState(
  domain: string,
  subpath: string,
  pathname: string,
  query?: ParsedUrlQuery,
  versionCookie?: string,
  subpathsInCart?: string
) {
  const INIT_STATE = setPrefix(
    buildServerState({
      domain,
      subpath,
      pathname,
      query,
      versionCookie,
      subpathsInCart
    }),
    ''
  )
  setGlobalState(INIT_STATE, true)

  return INIT_STATE
}
// const isDev = process.env.env != 'prd'

function buildServerState(params: {
  domain: string
  subpath: string
  pathname: string
  query?: ParsedUrlQuery
  versionCookie?: string
  subpathsInCart?: string
}) {
  // const t = process.hrtime()
  const { domain, subpath, versionCookie, subpathsInCart } = params
  // const domainData = isDev ? 'bvf.crossian.dev' : 'bvf.crossian.com'

  const serverState: ServerState = {}
  if (process._repository.default_store?.setting) {
    const defaultSetting = deepCloneObject(
      process._repository?.default_store
    ) as S3Types.default_store
    removeUnexpectedDomain(defaultSetting, domain)
    serverState.settingDefault = defaultSetting
    delete serverState?.settingDefault?.setting?.static
  }
  if (process._repository.domains?.[domain]) {
    serverState.domains = { [domain]: process._repository?.domains[domain] }
  }

  const store_id = serverState?.domains?.[domain]?.store_id

  if (store_id) {
    let store = process._repository?.stores?.[store_id] as S3Types.store
    const pages = process._repository?.pages || {}
    const products = deepCloneObject(process._repository?.products || {})
    let settingBvfDomain = (process._repository?.bvf_domain_settings || {})?.[domain]
    settingBvfDomain = deepCloneObject(settingBvfDomain)
    serverState.settingBvf = { default: settingBvfDomain }

    const collectionsAllDomain = process._repository?.collections || {}
    delete serverState?.settingBvf?.default?.after_sale

    if (store) store = deepCloneObject(store)
    delete store?.setting?.slug_history
    delete store?.setting?.publisher_history

    let collections = settingBvfDomain?.collection_ids?.reduce(
      (obj: S3Types.collections, item: string) => ({
        ...obj,
        [item]: collectionsAllDomain[item]
      }),
      {}
    ) as S3Types.collections
    if (collections) collections = deepCloneObject(collections)

    if (store && store.pages) {
      Object.entries(store.pages).forEach(([page_path, page]) => {
        if (page) {
          const pageId = page?.setting?.settings?.general?.id || ''
          page = deepCloneObject(pages?.[pageId] as S3Types.pages)
          removeUnavailableProperties(page?.setting?.variants)
          if (store?.pages) {
            const pbaseId = page?.setting?.settings?.general?.pbase_id || ''
            const pbase = process._repository.pbases?.[pbaseId]
            if (pbase?.mapping_size && page.setting) {
              page.mapping_size = pbase.mapping_size
            }
            const mapping_properties =
              process._repository.ppages?.[page?.setting?.settings?.general?.ppage_id ?? 'a']
                ?.setting?.variants?.option_values
            if (mapping_properties) {
              page.mapping_properties = mapping_properties
            }
            if (
              (page_path == 'cupipads' || page_path == 'cupi') &&
              (domain == 'vabeli.com' || domain == 'bvf2.crossian.com')
            ) {
              if (
                (versionCookie === 'bv11' ||
                  versionCookie === 'bv12' ||
                  versionCookie === 'bv13' ||
                  versionCookie === 'bv14') &&
                page &&
                page.setting
              ) {
                const gallery = deepCloneObject(page.setting?.gallery)
                page.setting.gallery = gallery.map(
                  (img: string) => imageMapping?.[img] || img || ''
                )
                if (versionCookie !== 'bv13' && versionCookie !== 'bv14') {
                  page.setting.settings.general.title = 'CupiPads - Invisible Lifting Bra ⚡'
                }
              }
            }
            store.pages[page_path] = page
          }
        }
      })

      buildCollections(store, collections, products as S3Types.products)
      serverState.analytics = getAnalytics(store.pages, subpath, subpathsInCart)

      Object.keys(store.pages).map((page_path) => {
        if (page_path === subpath) {
          const { reviews, ratings } = compileReviews(
            store?.pages?.[page_path]?.reviews || [],
            store?.pages?.[page_path]?.ratings as S3Types.page_rating
          )
          store.pages = immutable.set(store?.pages || {}, `${page_path}.reviews`, reviews)
          store.pages = immutable.set(store?.pages || {}, `${page_path}.ratings`, ratings)
        } else if (store?.pages) {
          store.pages = immutable.set(store?.pages || {}, `${page_path}.mapping_properties`, {})
          store.pages = immutable.set(store?.pages || {}, `${page_path}.mapping_size`, {})
          store.pages = immutable.set(store?.pages || {}, `${page_path}.reviews`, [])
          store.pages = immutable.set(store?.pages || {}, `${page_path}.ratings`, {})
          store.pages = immutable.set(store?.pages || {}, `${page_path}.setting`, {})
          store.pages = immutable.set(store?.pages || {}, `${page_path}.promotions`, undefined)
        }
      })
      serverState.promotions = getPromotions(store)
    }

    serverState.store = store

    const publisher =
      process._repository.publishers?.[store?.setting?.settings?.general?.publisher_id ?? '1']
    if (publisher) {
      serverState.paygates = paymentGatewaysHardcoded(domain, getPaymentGateways(publisher))
    }

    // serverState.customConfig = customConfig(domain, subpath)
  }
  serverState.surveys = process._repository?.surveys
  return serverState
}

const getPaymentGateways = (publisher: S3Types.publisher) => {
  const payments = publisher.payments
  const creditcardType = payments?.creditcard
  const paypalexType = payments?.paypalex
  const creditcardId =
    creditcardType === 'DEFAULT'
      ? process._repository?.default_paygate?.creditcard
      : creditcardType === 'SPECIFIC'
      ? payments?.creditcard_id
      : null
  const paypalexId =
    paypalexType === 'DEFAULT'
      ? process._repository?.default_paygate?.paypalex
      : paypalexType === 'SPECIFIC'
      ? payments?.paypalex_id
      : null
  return {
    ...(creditcardId && { creditcard: process._repository.paygates?.[creditcardId] }),
    ...(paypalexId && { paypalex: process._repository.paygates?.[paypalexId] })
  }
}

const getAnalytics = (pages?: S3Types.pages, subpath = '', subpathsInCart = '') => {
  let subpathGetInfo = subpath
  if (!subpath) {
    subpathGetInfo = subpathsInCart.includes('goody') ? 'goody' : subpathsInCart.split(',')?.[0]
  }
  if (pages) {
    if (pages?.[subpathGetInfo]) {
      return {
        pixel_ids: pages?.[subpathGetInfo]?.setting?.settings?.analytics?.pixel_ids ?? [],
        content_id: pages?.[subpathGetInfo]?.setting?.settings?.analytics?.content_id ?? '',
        tiktok_ids: pages?.[subpathGetInfo]?.setting?.settings?.analytics?.tiktok_ids ?? [],
        snapchat_ids: pages?.[subpathGetInfo]?.setting?.settings?.analytics?.snapchat_ids ?? [],
        pinterest_ids: pages?.[subpathGetInfo]?.setting?.settings?.analytics?.pinterest_ids ?? [],
        google_conversion_id:
          pages?.[subpathGetInfo]?.setting?.settings?.analytics?.google_conversion_id ?? '',
        google_conversion_label:
          pages?.[subpathGetInfo]?.setting?.settings?.analytics?.google_conversion_label ?? ''
      }
    }
    return {
      pixel_ids: [],
      tiktok_ids: [],
      snapchat_ids: [],
      pinterest_ids: [],
      google_conversion_id: '',
      google_conversion_label: ''
    }
  }
}

const getPromotions = (store: S3Types.store): ServerState['promotions'] => {
  const promotionMapping = (promotions: S3Types.promotions): S3Types.promotions => {
    return Object.fromEntries(
      Object.entries(promotions).map(([key, value]) => [
        key,
        {
          code: value.code,
          value: value.value,
          type: value.type
        }
      ])
    )
  }

  return {
    default: promotionMapping(process._repository.default_store?.promotions ?? {}),
    ...Object.fromEntries(
      Object.entries(store.pages ?? {}).map(([slug, page]) => [
        slug,
        promotionMapping(page?.promotions ?? {})
      ])
    )
  }
}

const paymentGatewaysHardcoded = (domain: string, paygates: ServerState['paygates']) => {
  const paygateContact = hardcodePaygateContact(domain)
  return {
    ...(paygates?.creditcard && {
      creditcard: {
        ...paygates?.creditcard,
        contact: { ...paygates?.creditcard.contact, ...paygateContact }
      }
    }),
    ...(paygates?.paypalex && {
      paypalex: {
        ...paygates?.paypalex,
        contact: { ...paygates?.paypalex.contact, ...paygateContact }
      }
    })
  }
}

const removeUnexpectedDomain = (setting: S3Types.default_store, domain = '') => {
  if (domain) {
    delete setting.setting.settings.general.after_sale
    delete setting.setting.settings.contact
    delete setting.setting.settings.general.traffic
    delete setting.setting.settings.general.logrocket
    delete setting.setting.settings.general.onselless_sellable
    delete setting.setting.settings.general.shophelp_disabled
  }
  return setting
}

const compileReviews = (listReview: S3Types.page_reviews, rating: S3Types.page_rating) => {
  const sellpageReviews = [
    ...(listReview || []),
    ...((rating?.user_ratings as S3Types.page_reviews) || [])
  ]
  const reviews = listReview?.slice(0, 3) || []
  const ratings: S3Types.page_rating = {
    ratio_rating: rating?.ratio_rating,
    user_ratings: reviews?.length == 3 ? [] : rating?.user_ratings?.slice(0, 3 - reviews?.length),
    avgRated: Number(caculatorRatingAvg(listReview, rating?.ratio_rating)),
    totalRating: sellpageReviews?.length || 0
  }
  return { reviews, ratings }
}

const caculatorRatingAvg = (listReview: S3Types.page_review[], ratioRating: number) => {
  const totalRated = listReview?.reduce(
    (total: number, review: S3Types.page_review) => total + (review.rating || 0),
    0
  )

  const avgRated = ((ratioRating || 0) + Math.ceil(totalRated / listReview?.length)) / 2
  return Number((avgRated || 0).toString().slice(0, 3)).toFixed(1)
}
