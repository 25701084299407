import getLogRocket from './logRocket'

const loggedEvent: Record<string, boolean> = {}

function log(evt: string) {
  const { logRocket } = getLogRocket()
  // MixPanel.track(evt)
  logRocket?.track(evt)
}

function logOnce(evt: string) {
  if (evt && !loggedEvent[evt]) {
    loggedEvent[evt] = true
    log(evt)
  }
}

function forget(evt: string) {
  delete loggedEvent[evt]
}

export function logProductEvent(
  action:
    | 'version_bv10'
    | 'version_bv11'
    | 'version_bv12'
    | 'version_bv13'
    | 'version_bv14'
    | 'view_product'
    | 'order_summary'
    | 'add_to_cart'
    | 'remove_from_cart'
    | 'open_cart'
    | 'checkout_credit_card'
    | 'checkout_paypal_cart'
    | 'checkout_paypal_buy_now'
    | 'paid_credit_card'
    | 'paid_paypal'
    | 'payment_success'
    | 'payment_failure'
    | 'payment_success_paypal_cart'
    | 'payment_success_paypal_buy_now'
    | 'payment_success_creadit_card'
    | 'paypal_pay_now'
    | 'cart_icon'
    | 'size_fit'
    | 'size_calculator'
    | 'find_my_size'
    | 'close_size_calculator'
    | 'view_see_more'
    | 'see_more'
    | 'see_less'
    | 'write_review'
    | 'show_reviews'
) {
  const eventName = {
    version_bv10: 'VERSION_BV10',
    version_bv11: 'VERSION_BV11',
    version_bv12: 'VERSION_BV12',
    version_bv13: 'VERSION_BV13',
    version_bv14: 'VERSION_BV14',
    view_product: 'VIEW_PRODUCT',
    order_summary: 'ORDER_SUMMARY',
    add_to_cart: 'ADD_TO_CART',
    remove_from_cart: 'REMOVE_FROM_CART',
    open_cart: 'OPEN_CART',
    checkout_credit_card: 'CHECKOUT_CREDIT_CARD',
    checkout_paypal_cart: 'CHECKOUT_PAYPAL_CART',
    checkout_paypal_buy_now: 'CHECKOUT_PAYPAL_BUY_NOW',
    paid_credit_card: 'PAID_CREDIT_CARD',
    paid_paypal: 'PAID_PAYPAL',
    payment_success: 'PAYMENT_SUCCESS',
    payment_failure: 'PAYMENT_FAILURE',
    payment_success_paypal_buy_now: 'PAYMENT_SUCCESS_PAYPAL_BUY_NOW',
    payment_success_paypal_cart: 'PAYMENT_SUCCESS_PAYPAL_CART',
    payment_success_creadit_card: 'PAYMENT_SUCCESS_CREDIT_CARD',
    paypal_pay_now: 'PAYPAL_PAY_NOW',
    paypal_continue: 'PAYPAL_CONTINUE',
    cart_icon: 'CART_ICON',
    size_fit: 'SIZE_FIT',
    size_calculator: 'SIZE_CALCULATOR',
    find_my_size: 'FIND_MY_SIZE',
    close_size_calculator: 'CLOSE_SIZE_CALCULATOR',
    view_see_more: 'VIEW_SEE_MORE',
    see_more: 'SEE_MORE',
    see_less: 'SEE_LESS',
    write_review: 'WRITE_REVIEW',
    show_reviews: 'SHOW_REVIEWS'
  }[action]
  eventName && log(eventName)
}

export function logViewProduct(route: any) {
  logOnce('VIEW_PRODUCT')

  const query = route.query
  let eventName = 'DIRECT_VIEW_PRODUCT'
  if (query.p && query.ref === 'sms_lead') {
    eventName = `LEAD_CONVERTER_VIEW_PRODUCT`
  }
  if (query['utm_campaign']) {
    eventName = 'CLICK_FB_VIEW_PRODUCT'
  }
  logOnce(eventName)

  query.p && logOnce(`APPLY_${query.p}`)
}

export function logSMSImpression(action: 'open' | 'typing' | 'sub' | 'close' | 'header') {
  const eventName = {
    open: 'LEAD_CONVERTER_IMPRESSION',
    close: 'LEAD_CONVERTER_CLOSE',
    sub: 'LEAD_CONVERTER_SUBSCRIBE',
    typing: 'LEAD_CONVERTER_FILL_PHONE',
    header: 'OPEN_HEADER_OFFER'
  }[action]
  eventName && logOnce(eventName)
}

export function logProductPage(action: 'variant' | 'quantity' | 'buy' | 'addCart') {
  const eventName = {
    variant: 'PRODUCT_CHOOSE_VARIANT',
    quantity: 'PRODUCT_CHOOSE_QUANTITY',
    buy: 'PRODUCT_BUY_IT_NOW',
    addCart: 'ADD_TO_CART'
  }[action]
  eventName && logOnce(eventName)
}

export function logCart(action: 'remove' | 'quantity' | 'buy' | 'close') {
  const eventName = {
    remove: 'PRODUCT_CART_REMOVE_LINE',
    quantity: 'PRODUCT_CART_CHOOSE_QUANTITY',
    buy: 'PRODUCT_CART_PROCESS_CHECKOUT',
    close: 'PRODUCT_CART_CLOSE'
  }[action]
  eventName && logOnce(eventName)
}

export function logCheckoutAction(
  action: 'email' | 'phone' | 'shipping' | 'card' | 'order_token_fail'
) {
  const eventName = {
    email: 'CHECKOUT_FILL_EMAIL',
    phone: 'CHECKOUT_FILL_PHONE_NUMBER',
    shipping: 'CHECKOUT_FILL_SHIPPING_INFORMATION',
    card: 'CHECKOUT_FILL_CARD_INFORMATION',
    order_token_fail: 'TOKEN_ORDER_NOT_FOUND'
  }[action]
  eventName && logOnce(eventName)
}

export function logPaypal(action: 'initCard' | 'initCheckout' | 'success' | 'fail' | 'cancel') {
  const eventName = {
    initCheckout: 'PAYPAL_EXPRESS_CHECKOUT',
    initCard: 'PAYPAL_EXPRESS_CART',
    success: 'PAYPAL_SUCCESS',
    fail: 'PAYPAL_FAILED',
    cancel: 'PAYPAL_CANCEL'
  }[action]
  eventName && log?.(eventName)
  action === 'success' && log?.('PAYMENT_SUCCESS')
}

export function logMakePayment(action: 'success' | 'fail') {
  const eventName = {
    success: 'MAKE_PAYMENT_SUCCESS',
    fail: 'MAKE_PAYMENT_FAILED'
  }[action]
  eventName && log?.(eventName)
  action === 'success' && log?.('PAYMENT_SUCCESS')
  action === 'fail' && forget?.('CHECKOUT_FILL_CARD_INFORMATION')
}

export function logThankyou(action: 'visit' | 'confirm') {
  const eventName = {
    visit: 'VISIT_THANKYOU',
    confirm: 'CONFIRM_BILLING_ADDRESS'
  }[action]
  logOnce(eventName)
}

export function logDuplicatedOrder(action: 'showNoti') {
  const eventName = {
    showNoti: 'SEE_DUPLICATED_NOTIFICATION'
  }[action]
  log?.(eventName)
}

export function logShopHelp(action: 'BADGE' | 'OPEN' | 'STATIC') {
  let eventName = ''
  switch (action) {
    case 'BADGE':
      eventName = 'CLICK_SHOPHELP_BADGE'
      break
    case 'OPEN':
      eventName = 'CLICK_OPEN_SHOPHELP'
      break
    case 'STATIC':
      eventName = 'CLICK_SHOPHELP_STATIC'
      break
  }
  log?.(eventName)
}
